import {
  Grid,
} from "@mui/material";
import { useAppSelector } from "src/redux/hooks";

export const MarkCommissionsAsPaidInTheFuture = () => {

  const isDeveloper = useAppSelector(state => state.auth?.isDeveloper);

  return (
    isDeveloper ? (<>
      <h3> WORK IN PROGRESS Mark Commissions as Paid In The Future</h3>
      <p>Mark all commissions on invoices as paid in the future</p>

      <Grid container spacing={2}>
        <Grid item xs={8}>

        </Grid>

        <Grid item xs={8}>

        </Grid>

        <Grid item xs={8}>

        </Grid>

        <Grid item xs={8}>

        </Grid>
      </Grid>
    </>
    ) : <></>
  );
};
